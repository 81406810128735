<template>
  <Wating/>
</template>

<script>
import Wating from "@/components/Wating";

export default {
  name: "TurnsManage",
  components: {Wating}
}
</script>

<style scoped>

</style>